import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames/bind"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Banner from "../components/banner"
import UspsBanner from "../components/banner-usps"
import Testimonials from "../components/testimonials"

import Pricing from "../components/pricing"
import StickyCTA from "../components/stickyCTA"

export default function Template({
  variant,
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark

  const testimonials = frontmatter.testimonials.map(testimonial => ({
    title: testimonial.title,
    image: <Img fluid={testimonial.image.childImageSharp.fluid} />,
  }))

  const tilteClass = classNames({
    "font-weight-light mb-4": true,
    "mt-3": variant,
    x: !variant,
  })

  const heroUsps = [
    `Huis verhuizen in ${frontmatter.title}`,
    `Zakelijk verhuizen in ${frontmatter.title}`,
    `Internationaal verhuizen`,
    `Spoed verhuizing in ${frontmatter.title}`,
    `Verhuislift huren in ${frontmatter.title}`,
    `Montage hulp tijdens verhuizen`,
    `Opslag voor uw verhuizing`,
  ]

  const usps = [
    `Snel en vertrouwelijk verhuizen`,
    `Flexibel en Professioneel`,
    `Vakkundig en kwalitatief`,
    `Voordelig/goedkoop verhuizen`,
    `Verhuizen in ${frontmatter.title}`,
    `Verhuizen in Nederland`,
    `Verhuizen naar het buitenland`,
    `Opslagruimte`,
    `Montage service`,
    `Spoed verhuizen`,
  ]

  return (
    <>
      <SEO title={`Verhuizen in ${frontmatter.title}`} />
      <Hero
        usps={heroUsps}
        img={
          <Img
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={frontmatter.heroImage.childImageSharp.fluid}
          />
        }
      >
        <h1 className="font-weight-ligt">Verhuizen in</h1>
        <h1 className="font-weight-bold">{frontmatter.title}</h1>
      </Hero>

      <section className="section--lg bg-offwhite">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-12 col-md-7">
              <h3 className="font-weight-light mb-2">
                Verhuisdeal24.nl helpt u ook verhuizen in {frontmatter.title}
              </h3>
              <p className="mb-3">
                Met onze expertise bent u ten alle tijde in goede handen. Of u
                nou binnen Nederland verhuist of Nederland verlaat voor een
                avontuur in het buitenland, wij hebben inmiddels ruime ervaring
                met verhuizen in zowel binnen- als buitenland
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white d-md-none">
        <div className="row no-gutters">
          <div className="col-12">
            <Pricing></Pricing>
          </div>
        </div>
      </section>
      <Testimonials testimonials={testimonials}></Testimonials>
      <Banner />
      <UspsBanner usps={usps}>
        <h3 className={tilteClass}>
          Verhuizen is waar wij goed in zijn.{" "}
          <span className="font-weight-bold">Ook in {frontmatter.title}.</span>
        </h3>
      </UspsBanner>
      <StickyCTA></StickyCTA>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        testimonials {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          id
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`
