import React from "react"
import Checked from "./checked"

function Banner({ usps, children }) {
  return (
    <section className="section--md bg-white">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {children}
            <ul className="list list--col-3">
              {usps.map((usp, i) => (
                <li key={usp}>
                  <h5 className="font-weight-medium">
                    <Checked>{usp}</Checked>
                  </h5>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
Banner.propTypes = {}

Banner.defaultProps = {}

export default Banner
